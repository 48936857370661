import { IconLoader2 } from "@tabler/icons-react"
import { ElementType, forwardRef, ReactNode } from "react"
import { tv, VariantProps } from "tailwind-variants"

import { PolymorphicComponentPropWithRef, PolymorphicRef } from "@/utils/types"

const buttonVariants = tv({
  base: "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  variants: {
    variant: {
      default: "bg-sherpa-blue-950 text-white hover:bg-sherpa-blue-800",
      outline:
        "border border-sherpa-blue-950 bg-white text-sherpa-blue-950 hover:border-sherpa-blue-800 hover:bg-slate-50 hover:text-sherpa-blue-800",
      secondary: "bg-slate-100 text-slate-700 hover:bg-slate-100/80",
      ghost: "hover:bg-slate-100 hover:text-slate-900",
      link: "text-slate-700 underline-offset-4 hover:underline",
    },
    size: {
      default: "h-10 px-4 py-2",
      sm: "h-9 px-3",
      lg: "h-11 px-8",
      icon: "h-10 w-10",
    },

    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
})

type Props = VariantProps<typeof buttonVariants> & {
  isLoading?: boolean
  className?: string
}

type ButtonProps<TComponent extends ElementType> = PolymorphicComponentPropWithRef<TComponent, Props>
type ButtonComponent = <TComponent extends ElementType = "button">(props: ButtonProps<TComponent>) => ReactNode | null

export const Button: ButtonComponent = forwardRef(
  <TComponent extends ElementType = "button">(props: ButtonProps<TComponent>, ref?: PolymorphicRef<TComponent>) => {
    const { as, children, isLoading, size = "default", variant = "default", className, ...rest } = props
    const Component = as ?? "button"

    return (
      <Component
        disabled={isLoading || props.disabled}
        className={buttonVariants({ variant, size, className })}
        ref={ref}
        {...rest}
      >
        {isLoading ? (
          <div className="flex items-center justify-center">
            <IconLoader2 className="h-4 w-4 animate-spin" />

            <div className="w-2" />

            {children}
          </div>
        ) : (
          <>{children}</>
        )}
      </Component>
    )
  },
)
