"use client"

import { useMutation } from "@tanstack/react-query"
import { useState } from "react"

import { Button } from "@/components/ui/Button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/Select"
import { useToastFactory } from "@/components/ui/Sonner"
import { Spacer } from "@/components/ui/Spacer"
import { devtoolsLoginAction } from "@/server/auth/devtoolsLoginAction"

type Props = {
  users: { email: string; tenantId: string; id: string }[]
  tenants: { id: string; name: string }[]
  onClose: () => void
}

export function DevtoolsImpersonate(props: Props) {
  const { tenants, users, onClose } = props

  const toasts = useToastFactory()
  const mutation = useMutation({
    mutationFn: devtoolsLoginAction,
    onSuccess: onClose,
    onError: () => toasts.onError(),
  })

  const [tenantId, setTenantId] = useState<string>(tenants[0]?.id ?? "")
  const [email, setEmail] = useState("")

  return (
    <>
      <h6 className="text-sm font-semibold text-slate-800">Impersonate user</h6>

      <Spacer className="h-2" />

      <div className="space-y-2">
        <Select value={tenantId} onValueChange={setTenantId}>
          <SelectTrigger>
            <span className="max-w-[280px] truncate">
              <SelectValue placeholder="Tenant" />
            </span>
          </SelectTrigger>

          <SelectContent>
            {tenants.map((tenant) => {
              return (
                <SelectItem key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </SelectItem>
              )
            })}
          </SelectContent>
        </Select>

        <Select value={email} onValueChange={setEmail} disabled={!tenantId}>
          <SelectTrigger>
            <span className="max-w-[280px] truncate">
              <SelectValue placeholder="Email" />
            </span>
          </SelectTrigger>

          <SelectContent>
            {users
              .filter((u) => u.tenantId === tenantId)
              .map((user) => {
                return (
                  <SelectItem key={user.email} value={user.email}>
                    {user.email}
                  </SelectItem>
                )
              })}
          </SelectContent>
        </Select>

        <div className="flex justify-end">
          <Button
            size="sm"
            variant="secondary"
            disabled={!tenantId || !email || mutation.isPending}
            type="button"
            onClick={() => mutation.mutate({ email }, {})}
          >
            Login
          </Button>
        </div>
      </div>
    </>
  )
}
